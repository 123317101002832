.cr-second-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cr-modal-button{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.cr-modal-button button{
    border: 1px solid black;
    padding: 8px 20px;
    border-radius: 9999px;

}
.cr-stars svg{
    font-size: 33px;
}
.be-the-first{
    color: gray;
}
.be-the-first-star label svg{
    font-size: 20px;
}
.section-cr-main{
    padding: 59px 0px;
    background: rgba(247, 247, 247, var(--tw-bg-opacity));
    margin: 31px 0px;
}
.how-was-your-overall{
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
}