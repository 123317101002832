.MyOrder-Section {
    /* border: 1px solid blue; */
}

.MyOrder-Section-Heading {
    /* border: 1px solid red; */
}

.MyOrder-Section-Heading h1 {
    text-align: left;
    font-size: 30px;
    font-family: var(---font02);
}

.orderlistmaincon {
    border: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 3px;
}

/* //// */

.Product-addedCart-Side-details {
    /* border: 1px solid red; */
    padding: 10px 0px !important;
}

.Product-details-addedCart-cancel-btn-con {}

.Product-details-addedCart-cancel-btn-con button {}

/* //// */

/* head  */

.orderlistmaincon {
    /* border: 1px solid red; */
    margin: 10px 0px;
}

.Orderlist-Details-Head-Con {
    /* border: 1px solid blue !important; */
    padding-top: 5px;
}

.OrderList-Details-Date {
    color: #565959;
    text-align: left;
    /* border: 1px solid blue; */
}

.OrderList-Details-Date h2 {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0px;
}

.OrderList-Details-Date h3 {
    font-size: 12px;
    line-height: 16px;
    margin: 5px 0px 0px 0px;
}

.OrderList-Status-Details {
    /* border: 1px solid blue; */
}

.OrderList-Status-Details {
    /* border: 1px solid red; */
    font-size: 12px;
    line-height: 16px;
}

.OrderList-Status-Details-btn {
    /* border: 1px solid green; */
    display: inline-block;
    padding: 3px 20px;
    border-radius: 20px;
    background: #0080008f;
    font-size: 12px;
    color: white;
    font-family: var(---font01);
    margin: 5px 0px;
}

.orderidcon {
    text-align: right;
}

/* end Head  */

.orderList-con {
    align-items: center;
}

.OrderProduct-Btn-Con button {
    border: none;
    width: max-content;
    padding: 6px 13px;
    font-size: 12px;
    background: black;
    color: white;
    border-radius: 2px;
}



/* Drawer  */

.Order-Details-Drawer {
    /* border: 1px solid red; */
}

.Order-Details-Drawer-Details-order-Id {
    border: 1px solid #e3e3e3;
    padding: 5px;
    border-radius: 5px;
    margin: 20px 0px 0px 0px;
}

.Order-Details-First-Part {
    /* border: 1px solid blue; */
    margin-bottom: 8px;
}

.Order-DetailsName {
    /* border: 1px solid red; */
}

.Order-DetailsName h2 {
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
}

.Order-Details-dec {}

.Order-Details-dec h3 {
    font-size: 13px;
    line-height: 16px;
    margin: 0px;
}

.Order-Details-Drawer-HeadDetails {
    /* border: 1px solid blue; */
}

/* // */

.Order-Details-Drawer-HeadDetails {
    /* border: 1px solid red; */
    padding: 10px 10px 10px 0px;
    border-bottom: 1px solid gray;
}

.Order-Details-Drawer-HeadDetails h2 {
    font-family: var(---font02);
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
}

.Order-Details-Drawer-HeadDetails p {
    margin: 0px;
}

.Order-details-Drawer-Shipment-details {
    /* border: 1px solid black; */
    padding: 10px;
}

.Order-details-Drawer-Product-Image {
    /* border: 1px solid red; */
    /* height: 70px; */
}

.Order-details-Drawer-Product-Image img {
    height: 100%;
    width: 100%;
}

.Order-details-Drawer-Product-Details {}

.Order-details-Drawer-Product-Details h3 {
    font-size: 15px;
    margin: 0px;
    margin: 0px 0px 3px 0px;
}


.Order-details-Drawer-Product-Details p {
    margin: 0px;

}

.Order-details-Drawer-Product-Details h4 {
    font-size: 15px;
    margin: 0px 0px 3px 0px;
    font-family: var(---font01);
}

.Order-details-Drawer-Product-Details h4 span {
    font-size: 13px;
    color: #565959;
}

.Order-details-Drawer-OrderTotal {
    font-size: 18px !important;
    font-weight: 700;
}

.Order-details-Drawer-Orderamount {
    font-size: 18px !important;
    font-weight: 700;
    color: #d70000;
}

.OrderList-Details-Date .ant-btn-default {
    background: #ffffff;
    border: none !important;
    text-align: left !important;
    padding: 0px !important;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.OrderList-Details-Date .ant-btn {
    font-size: 12px;
    height: auto !important;
    padding: 4px 15px;
    border-radius: 6px;
}

.Order-Details-Address p {
    margin: 0px;
    font-size: 13px !important;
    color: #565959;

}

.OrderList-Details-Date .ant-btn>span {
    display: inline-block;
    font-weight: 500 !important;
    color: #565959 !important;
}

.order-details-addedCart-price-name {
    display: flex;
}

.order-details-addedCart-price-name h3 {
    margin: 0px 10px;
    font-size: 14px;
}

@media(max-width:768px) {
    .orderidcon {
        text-align: left;
        margin: 10px 0px 10px 0px;
    }

    .OrderList-Details-Date {
        color: #565959;
        text-align: center;
    }
}

@media(max-width:576px) {
    .OrderList-Details-Date {
        color: #565959;
        text-align: left;
        margin: 10px 0px;
    }
}