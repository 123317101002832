.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion {
  --bs-accordion-border-color: #fff !important;
  --bs-accordion-active-bg: #fff !important;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgb(255, 255, 255);
}
.accordion-button:focus {
  box-shadow: #fff;
}

.service-para {
  color: #1b1b1b;
  font-weight: 300;
  font-family: var(---font01);
  /* font-weight: 300; */
}
.service-section-1-overlay-section {
  /* border: 1px solid blue; */
  transform: translate(0px, -180px);
}
.service-section-svg-overlay-btn-con {
  display: inline-block;
}
.service-section-overlay-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  padding: 8px;
  border-radius: 50px;
  background-color: #363636;
}

.service-section-overlay-btn svg {
  font-size: 20px;
  color: white;
  transition: all 0.4s;
}

.service-section-overlay-btn:hover svg {
  transform: rotate(-90deg);
}
.service-grid-left {
  padding: 84px 80px 94px 0;
}

.accordion-title {
  text-transform: capitalize;
  color: var(---colorBlue);
}

.accordion-button::after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANklEQVR4nGNgGGngPxSPWoAT/B8NIkLg/8gOov9UxvS3YOjHATHg/6gFhMD/0SAa8CBiGHQAAOITS7WQXzPpAAAAAElFTkSuQmCC") !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIUlEQVR4nGNgGAWjYBSMApLBfyrhgbNgFIyCUTAKGDABAOkcI91xODUvAAAAAElFTkSuQmCC") !important;

  /* transform: rotate(90deg); */
}
.accordion-button {
  padding: unset;
}
.accordion-body {
  padding: unset;
}
.input-field-from-to {
  margin-left: 9px;
}
.filter-apply-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  padding: 0.625rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: all;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.accordion-header {
  padding: 0px;
}
.main-sub-categories-list {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 7px;
}

.main-sub-categories-list a span{
  font-family: var(---font01);
  font-size: 16px;
  font-weight: 400;
}


.main-size-list div {
  /* margin-right: 1px; */
}
.main-groosmen-outfits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4vh;
}
.first-groosmen-outfits {
  display: flex;
  gap: 15px;
}
.first-groosmen-outfits div {
  border: 1px solid #000;
  border-radius: 9999px;
  padding: 2px 17px;
}
.second-groosmen-outfits {
  border: 1px solid #000;
  border-radius: 9999px;
  padding: 2px 17px;
}
.dropdown-groosmen-outfits {
  border-radius: 7px;
  padding: 8px 23px;
}
.dropdown-inner-list-groosmen-outfits div {
  border: 1px solid #000;
  border-radius: 9999px;
  padding: 5px 17px;
  font-size: 12px;
}
.groosmen-outfits-option {
  display: flex;
  flex-direction: row;
  padding-right: 112px;
  gap: 11px;
}

/*  */
.product-card-main-con {
  /* border: 1px solid blue; */
}

.product-card-main-con a {
  text-decoration: none;
}

.product-card-body-con {
  /* border: 1px solid #fff;
  background-color: #b6b6b6; */
}

.product-card-Image-con {
  /* border: 1px solid green; */
  height: 65vh;
  position: relative;
}

.product-card-Image-con a {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.product-item__bg {
  /* border: 1px solid blue; */
  display: block;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  font-size: 0;
  line-height: 1;
  will-change: transform, opacity;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* image 2  */

.product-item_bg_under {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  font-size: 0;
  line-height: 1;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;
}

.double_image .product-itembg_under {
  opacity: 0;
}

.double_image:hover .product-itembg_under {
  opacity: 1;
}

/* // */

.product-item__image {
}

.product-item__image:hover .slideup > button {
  opacity: 100;
}

.product-item__image .slideup > button {
  opacity: 0;
}

.slideup > button {
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  color: var(---color-text);
  padding: 6px;
  cursor: pointer;
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: blur(10px);
  transition: all 0.3s;
}

.product-item__image:hover .slideup {
  bottom: 0;
}

.product-item__image .slideup {
  position: absolute;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.slideup {
  position: absolute;
  bottom: -60px;
  transition: bottom 0.25s ease-in;
  z-index: 9;
  width: 100%;
  background: #fff0;
  border-top: none;
}

.slideup button span {
  text-transform: uppercase;
  letter-spacing: 0.12em;
  font-family: var(---font01);
  font-size: 12px;
}

.slideup > button:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  background: #ffffff;
  opacity: 0.7;
}

/* /// */

/* card deatils  */

.Product-card-details-con {
  display: block;
  height: 100%;
  color: #212121;
  padding: 20px 0px;
}

.Product-card-details {
}

.product-card-product-name {
  /* height: 12vh;     */
}

.product-card-product-name h4 {
  font-family: var(---font01);
  font-size: 20px;
  text-transform: capitalize;
}

.Product-card-product-review {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 15px 0px;
}

.Product-card-product-review-star {
}

.Product-card-product-review-star svg {
  font-size: 20px;
  margin: 0px 1px;
}

.Product-card-product-review-text {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0px 0px 10px;
}

.Product-card-product-review-text h5 {
  margin: 0px;
  font-family: var(---font01);
  font-size: 15px;
}

.Product-card-product-review-price {
}

.Product-card-product-review-price h6 {
  font-family: var(---font01);
}


.product-cart-list-main-product-main-con {
/* border: 1px solid black; */
margin: 10vh 0px;
}

.product-cart-list-main-product-main-con h4{
  font-family: var(---font03);
  font-size: 22px;
  font-weight: 400;
}

.product-cart-list-main-product-main-con p{
  font-family: var(---font03);
  font-size: 13px;
  font-weight: 300;
}


@media (max-width: 768px) {
  .product-card-product-name h4 {
    font-family: var(---font01);
    font-size: 20px;
  }

  .Product-card-product-review-star svg {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .product-card-main-con {
    margin: 20px 20px;
  }

  .product-card-Image-con {
    /* border: 1px solid green; */
    height: 80vh;
  }
  .product-cart-list-main-product-main-con p{
    font-family: var(---font03);
    font-size: 10px;
    font-weight: 300;
  }
}

@media (max-width: 576px) {
  .product-card-Image-con {
    /* border: 1px solid green; */
    height: 60vh;
  }
}
/*  */
.col-clothCard {
  padding-bottom: calc(var(--bs-gutter-x) * 0.5);
  padding-top: calc(var(--bs-gutter-x) * 0.5);
}
.sort-by {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.sort-by label {
  /* border: 1px red solid; */
  padding: 1px 5px;
  /* padding-right: 42px; */
  width: 121px;
}
select:focus {
  border: 1px solid #000;
}
.designer-ethnic-wear {
  font-size: 14px;
  font-weight: 400;
}

.product-page-filter-left h5 {
  font-family: var(---font02);
  color: var(---colorRed);
}

@media (max-width: 1023px) {
  .product-page-filter-left {
    /* display: none; */
  }
}
.main-product-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.product-page-filter-left {
  flex-basis: calc(20% - 20px);
}
.product-page-filter-right {
  flex-basis: calc(80% - 20px);
}

@media (max-width: 769px) {
  .product-page-filter-left {
    display: none;
  }
  .main-product-page {
    display: unset !important;
    justify-content: center;
  }
  .filter-sort-section {
    display: block;
  }
  .main-groosmen-outfits {
    display: none;
  }
}
@media (min-width: 770px) {
  .product-page-filter-left {
    display: block;
  }
  .filter-sort-section {
    display: none;
  }
}
