.YourOrder-Con-Main {
    border: 1px solid #E0E2E3;
    padding: 30px;
}

.YourOrder-Con-head-Main {
    /* border: 1px solid blue; */
    text-align: left;
}

.YourOrder-Con-head-Main h2 {
    text-align: left;
    text-transform: capitalize;
    font-family: var(---MainHeadFont);
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 1px;
    color: black;
}

.YourOrder-Con-Title-Main {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px;
    border-bottom: 1px solid #E0E2E3;
}

.YourOrder-Con-Title-Main h2 {
    font-size: 17px;
    margin: 0px;
    font-weight: 400;
    font-family: var(---font02);
}

.YourOrder-Con-details-Main {
    /* border: 1px solid red; */
    border-bottom: 1px solid #E0E2E3;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.YourOrder-Con-details-text {
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
}

.YourOrder-Con-details-text h2 {
    font-size: 17px;
    font-family: var(---font02);
    margin: 0px;
    color: #55585B;
}

.YourOrder-Con-details-text span {
    font-size: 17px;
    font-family: var(---font02);
    margin: 0px 5px 0px 5px;
}

.YourOrder-Con-details-Main h2 {
    font-size: 17px;
    font-family: var(---font02);
    margin: 0px;
    font-weight: 400;
}

.YourOrder-totoal {
    color: black !important;
}

.YourOrder-Con-radio-text {
    display: block;
}

.YourOrder-Con-radio-text-con {
    display: flex;
    justify-content: space-between;
    font-family: var(---font02);
}

.YourOrder-Con-radio-text-con label {
    text-align: end;
    width: 100%;
    margin: 0px 10px 0px 0px;
}

.YourOrder-Con-radio-text-con input {}

@media(max-width:768px) {
    .YourOrder-Con-Main {
        margin-top: 30px;
    }
}