.YourAddresses-main-con {
    /* border: 1px solid blue; */

}

.YourAddresses-card-add {
    /* border: 1px solid red; */
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed gray;
    border-radius: 8px;
    margin: 20px 0px 0px 0px;
}

.YourAddresses-card-body-add {
    /* border: 1px solid green; */
}

.YourAddresses-card-body-add svg {
    font-size: 50px;
    color: gray;
}

.YourAddresses-card-body-add h3 {
    font-size: 25px;
    font-family: var(---font01);
}


/* //// */

.YourAddresses-card {
    border: 1px solid #eeeeee;
    /* padding: 13px; */
    padding: 13px 13px 13px 13px;
    border-radius: 8px;
    margin: 20px 0px 0px 0px;
}

.YourAddresses-card-body {
    /* border: 1px solid red; */
}

.YourAddresses-card-Para {
    /* border: 1px solid rebeccapurple; */
    /* height: 30vh; */
}

.YourAddresses-card-Para p {
    font-size: 14px;
    text-align: left;
    margin: 0px 0px 10px 0px;
}

.YourAddresses-card-Para h3 {
    font-size: 14px;
    text-align: left;
}

.YourAddresses-card-Para h3 span {}

.YourAddresses-card-Para-btn {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-evenly;
}

.YourAddresses-card-Para-btn button {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.YourAddresses-card-Para-btn {
    padding: 7px 0px;
}

.YourAddresses-card-Para-btn h3 {
    font-size: 14px;
    margin: 0px;
    font-family: var(---font01);
}

.YourAddresses-border {
    border-right: 2px solid black !important;
}

.YourAddress-Card-Remove-btn-con {
    text-align: right;
}

.YourAddress-Card-Remove-btn-con svg {
    color: red;
    font-size: 20px;
}

.YourAddresses-border-remove {
    color: red;
}

.YourAddresses-card-Para-btn button{
    margin: 20px 0px 0px 0px;
    background: #ff000026;
    border-radius: 10px;
    padding: 2px 20px;
    font-size: 12px;
}